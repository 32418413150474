<template>
    <div class = "change-address-status-view">
        <div v-if = "!is_editable && is_kit_delivered" class = 'change-address-saved-view'>
            <img src="@/assets/ChangeShippingAddress/success_icon.svg" />
            <div class = "bold-content">
                Your Kit Has Already Been Delivered
            </div>
            <div v-if = "need_blood_draw!=null" class = 'next-step-notice clickable' 
            @click = "clickNextStep">
               <span class = 'bold'>Next Step</span>
            </div>
            <div v-else  class = 'next-step-notice'>
               <span class = 'bold'>What's Next: </span>We will send your kit as soon as possible
            </div>
            <div class = "regular-content">
                If you haven't received your kit, please <span class = 'link' @click ='clickContactUs'>Contact Us</span>.
            </div>
        </div>
        <div v-else-if = "!is_editable" class = 'change-address-failed-view'>
            <img src="@/assets/ChangeShippingAddress/failed_icon.svg" />
            <div class = "bold-content">
                Change Address Failed  
            </div>
            <div v-if = "need_blood_draw!=null" class = 'next-step-notice clickable' @click = "clickNextStep">
               <span class = 'bold'>Next Step</span>
            </div>
            <div class = "regular-content">
                If your package will be/has been delivered to a wrong address, please <span class = 'link' @click ='clickContactUs'>Contact Us</span>.
            </div>
        </div>
        
        <div v-if = "edits_saved" class = 'change-address-saved-view'>
            <img src="@/assets/ChangeShippingAddress/success_icon.svg" />
            <div class = "bold-content">
                Shipping Address Saved!
            </div>
            <div v-if = "need_blood_draw!=null" class = 'next-step-notice clickable' 
            @click = "clickNextStep">
               <span class = 'bold'>Next Step</span>
            </div>
            <div v-else  class = 'next-step-notice'>
               <span class = 'bold'>What's Next: </span>We will send your kit as soon as possible
            </div>
            <div class = "regular-content">
                If you need to edit your address <span class = 'link' @click ='clickContactUs'>Contact Us</span>.
            </div>
        </div>
        
    </div>
</template>

<script>
import { useShippingAddressStore } from '@/stores/ShippingAddressStore.ts';
import { mapActions, mapState } from 'pinia';
export default{
    created(){
        //as when edits_saved becomes true, the shippingAddressInputsView will be unMounted/Destroyed, and ChangeAddressStatusView will be created.
        //we will check is_concierge_valid again here based on current shipping address
        //1. if edits_saved == true: update is_concierge_valid according to current_shipping_address_data
        //   1.1 valid if the state is one of the CA，TX, FL
        //2. (when failed change) otherwise, is_concierge_valid won't change after we check based on previous_shipping_address_data
        // if(this.edits_saved){
        //     if(this.valid_concierge_states.includes(this.current_shipping_address_data.state)){
        //         this.updateIsConciergeValid(true);
        //     }else{
        //         this.updateIsConciergeValid(false);
        //     }
        // }
        // console.log('is_concierge_valid', this.is_concierge_valid);
    },
    computed:{
        ...mapState(useShippingAddressStore,[
            'is_editable',
            'edits_saved',
            'is_kit_delivered',
            'need_blood_draw',
            'is_concierge_valid',
            'valid_concierge_states',
            'current_shipping_address_data',
        ])
    },
    methods:{
        ...mapActions(useShippingAddressStore,[
            'goToNextStep',
            'updateIsConciergeValid'
        ]),
        clickContactUs(){
            window.open('https://www.vibrant-wellness.com/Contact','_blank');
        },
        clickNextStep(){
            this.goToNextStep(this.$router);
        }
    }
}
</script>

<style scoped>
.change-address-status-view{
    margin-top: 80px;
    padding:0 316px;
}

.change-address-saved-view,
.change-address-failed-view{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bold-content{
    margin-top: 62px;
    color: #0E4672;
    text-align: center;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
}
.change-address-failed-view .regular-content{
    margin-top:36px;
    color: #0E4672;
    text-align: center;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
}
.next-step-notice{
    margin-top: 36px;
    padding-top:26px;
    padding-bottom:26px;
    padding-left:117px;
    padding-right: 116px;
    border-radius: 4px;
    background: #20A8C3;

    text-align: center;
    color: #FFF;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
}
.clickable{
    cursor: pointer;
}
.next-step-notice .bold{
    font-weight: 700;
}
.change-address-saved-view .regular-content{
    margin-top: 36px;
    color: #0E4672;
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
}
.link{
    color: #20A8C3;
    text-decoration: underline;
    cursor:pointer;
}
@media only screen and (max-width: 1260px) {
    .change-address-status-view{
        margin-top: 80px;
        padding:0 100px;
    }
    .next-step-notice{
        padding-left:58px;
        padding-right: 58px;
    }
}
@media only screen and (max-width: 767px) {
    .change-address-status-view{
        margin-top: 49px;
        padding-left:28px;
        padding-right:27px;
    }
    .change-address-failed-view img,
    .change-address-saved-view img{
        width: 120px;
        height: 120px;
    }

    .bold-content{
        margin-top: 36px;
        color: #0E4672;
        text-align: center;
        font-family: 'Roboto';
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
    }
    .next-step-notice{
        margin-top: 24px;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 13px;
        padding-right: 14px;
        border-radius: 4px;
        background: #20A8C3;
        color: #FFF;
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
    }
    .change-address-failed-view .regular-content{
        margin-top: 24px;
        color: #0E4672;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
    .change-address-saved-view .regular-content{
        margin-top: 24px;
        color: #0E4672;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
}
</style>