<template>
  <div
    id="page-container"
    v-loading="loadingBillingInfo"
    element-loading-background="white"
  >
    <div class="sourceContainer" v-if="!loadingBillingInfo">
      <div class="orderSource">
        Order from {{ provider_name }}, {{ clinic_name }}
      </div>
    </div>
    <div class="pageBodyContainer" v-if="!loadingBillingInfo">
      <PaymentOverview :payFixedAmount="payFixedAmount" />
      <div class="paymentInfo">
        <div class="paymentInfoHeader">Payment Information</div>
        <div class="headerDivider" />
        <div class="subpartContainer">
          <div class="paymentSubtitle">Payment Method</div>
          <PaymentRadio
            v-if="!payFixedAmount"
            :options="PaymentOptions"
            @onChange="handlePaymentMethodChange"
            :isPaymentMethod="true"
            :preSelectValue="this.count >= 3 ? 'payLater' : ''"
          />
          <PaymentRadio
            v-else
            :options="InternalPaymentOptions"
            @onChange="handlePaymentMethodChange"
          />
        </div>
        <BnplContainer v-if="paymentMethod == 'payLater'" />
        <!-- CardPayContainer means pay with Stax card platform -->
        <CardPayContainer v-if="paymentMethod == 'card' && !is_markup_order && !is_international_payment" />
        <!-- HSACardPayContainer means pay with Stripe card platform, can switch between stax and stripe-->
        <HsaCardPayContainer v-if="paymentMethod == 'card' && (is_markup_order || is_international_payment)" :isHSACard="false"/>
        <HsaCardPayContainer v-if="paymentMethod == 'hsa/fsa'" :isHSACard="true"/>
        <!-- <DigitalWalletContainer v-show="paymentMethod == 'digitalWallet'" /> -->
      </div>
      <img id="footerImage" alt="Footer Image" src="@/assets/footerImage.svg" />
    </div>
  </div>
</template>

<script>
import PaymentRadio from "@/components/payment/PaymentRadio.vue";
import PaymentOptions from "@/utils/paymentMethodOptions.js";
import InternalPaymentOptions from "@/utils/InternalPaymentMethodOptions.js";
import PaymentOverview from "@/components/payment/PaymentOverview.vue";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapState, mapActions } from "pinia";
import BnplContainer from "@/components/payment/BnplContainer.vue";
import CardPayContainer from "@/components/payment/CardPayContainer.vue";
import HsaCardPayContainer from "@/components/payment/HsaCardPayContainer.vue";

export default {
  name: "PaymentView",
  components: {
    PaymentRadio,
    PaymentOverview,
    BnplContainer,
    CardPayContainer,
    HsaCardPayContainer,
    // DigitalWalletContainer,
  },
  data() {
    return {
      paymentMethod: "",
      PaymentOptions,
      InternalPaymentOptions,
      loadingBillingInfo: true,
      payFixedAmount: localStorage.getItem("amount_to_pay") ? true : false,
      is_markup_order: false,
      case_insensitive_domestic_name_collection:[
        'US',
        'UNITED STATES',
        'THE UNITED STATES',
        'UNITED STATE',
        'THE UNITED STATE',
        'AMERICA',
        'U.S.',
        'U.S'
      ],
      is_international_payment:false,
    };
  },
  async created() {
    window.scrollTo(0, 0);
    try {
      const results = await Promise.all([
        this.getBillingInfo(),
        this.fetchClinicInfo(),
        // this.fetchPatientAddressInfo(),
        this.fetchCustomerInfo(),
      ]);
      const patientAddressInfo = await this.fetchPatientAddressInfo();
      let billingInfo = results[0];
      this.is_markup_order = billingInfo.is_markup_order;
      this.setIsMarkupOrder(this.is_markup_order);
      this.is_international_payment = patientAddressInfo.country 
      ? 
      !this.case_insensitive_domestic_name_collection.includes(patientAddressInfo.country.toUpperCase())
      :
      (
        results[2].countryName
        ?
        !this.case_insensitive_domestic_name_collection.includes(results[2].countryName.toUpperCase())
        :
        false
      );
      console.log('mark up and international', this.is_markup_order, this.is_international_payment);
      let accessionId = results[1].accessionId;
      let customerId = results[1].customerId;
      let sampleId = results[1].sampleId;
      let chargeMethod = results[1].chargeMethod;
      let patientId = results[1].patientId;
      let addressCheckNeeded = results[1].addressCheckNeeded;
      let bloodDrawNeeded = results[1].bloodDrawNeeded;
      let token = localStorage.getItem("pnsToken");
      this.addPatientIDToMatomo(patientId);
      // set user id (patient_id) for full story
      this.addPatientIDToFullStory(patientId);
      // this.$matomo && this.$matomo.setUserId(this.patientId);
      let directToBlooddraw =
        localStorage.getItem("direct_to_blooddraw") == "true";
      // check if the order balance is 0
      if (Number(billingInfo.total) <= 0) {
        // if the order is not paid by patient
        if (chargeMethod != "patientPayLater" || directToBlooddraw) {
          if (addressCheckNeeded) {
            // const redirectUrl = `https://pns-singlepage.pages.dev/uploadShippingAddress?customer_id=${customerId}&sample_id=${sampleId}&accession_id=${accessionId}&token=${token}&bloodDrawNeeded=${bloodDrawNeeded}&patient_id=${patientId}&direct_to_blooddraw=${directToBlooddraw}`;
            // window.location.href = redirectUrl;
            this.$router.push(
              `uploadShippingAddress?customer_id=${customerId}&sample_id=${sampleId}&accession_id=${accessionId}&token=${token}&bloodDrawNeeded=${bloodDrawNeeded}&patient_id=${patientId}&direct_to_blooddraw=${directToBlooddraw}`
            );
            return;
            // if the order is shipped to phlebotomist
          } else {
            // if the order is blood draw, push to blood_draw page
            if (bloodDrawNeeded) {
              // const redirectUrl = `https://pns.vibrant-wellness.com/bloodDraw?customer_id=${customerId}&sample_id=${sampleId}&accession_id=${accessionId}&token=${token}&patient_id=${patientId}&direct_to_blooddraw=${directToBlooddraw}`;
              // window.location.href = redirectUrl;
              this.$router.push(
                `bloodDraw?customer_id=${customerId}&sample_id=${sampleId}&accession_id=${accessionId}&token=${token}&patient_id=${patientId}&direct_to_blooddraw=${directToBlooddraw}`
              );
              return;
            } else {
              window.location.href =
                "https://www.vibrant-wellness.com/collection/";
              return;
            }
          }
          // if the order is paid by patient and balance is 0
        } else {
          this.$router.push("/payment_succeed");
        }
      }
      this.loadingBillingInfo = false;
    } catch (err) {
      this.loadingBillingInfo = false;
      console.log(err);
      this.$router.push("/failed");
    }
  },
  computed: {
    ...mapState(usePaymentStore, ["clinic_name", "provider_name", "count"]),
  },
  methods: {
    ...mapActions(usePaymentStore, [
      "fetchClinicInfo", 
      "getBillingInfo", 
      "setIsMarkupOrder",
      'fetchPatientAddressInfo',
      'fetchCustomerInfo',
    ]),
    isTestCustomerId(customerId) {
      return (
        customerId == 999997 ||
        customerId == 29520 ||
        customerId == 29818 ||
        customerId == 31157 ||
        customerId == 12005 ||
        customerId == 1000002
      );
    },
    handlePaymentMethodChange(value) {
      this.paymentMethod = value;
    },
    addPatientIDToFullStory(patientId){
      if (patientId) {
        const intervalId = setInterval(() => {
          if (window.FS) {
            window.FS('setIdentity', {
              uid: patientId.toString(),
              properties: {
                displayName: 'patient_' + patientId.toString()
              }
            });
            clearInterval(intervalId);
          }
        }, 500);
      }
    },
    addPatientIDToMatomo(patientId) {
      let width = window.innerWidth;
      let deviceType;
      if (width <= 767) {
        deviceType = 3;
      } else if (width <= 1024) {
        deviceType = 2;
      } else {
        deviceType = 1;
      } 
      if (patientId) {
        const intervalId = setInterval(() => {
          if (this.$matomo) {
            this.$matomo.setUserId(patientId);
            this.$matomo.trackEvent(
              "pns",
              "payment-page",
              "pns-login",
              patientId
            );
            this.$matomo.trackEvent(
              "pns",
              "payment-page",
              "pns-login-device",
              deviceType
            );
            clearInterval(intervalId);
          }
        }, 500);
      }
    },
  },
};
</script>

<style scoped>
/* :deep(.el-loading-spinner) {
  top: 20%;
} */
#page-container {
  min-height: calc(100vh - 106px);
}

.sourceContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}
.orderSource {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.52px;
  color: #2e557b;
  width: 56vw;
  margin-top: -20px;
}

.pageBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
  height: 1700px;
  position: relative;
}

.paymentInfo {
  width: 56vw;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.paymentInfoHeader {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.51px;
  color: #2e557b;
}

.subpartContainer {
  margin-bottom: 24px;
}

.headerDivider {
  border: 1px solid #bcccdc;
  margin: 16px 0;
}

@media only screen and (max-width: 1200px) {
  .orderSource {
    margin-top: 10px;
    margin-bottom: -20px;
  }
}

@media only screen and (max-width: 1024px) {
  #footerImage {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .orderSource {
    width: 90%;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: -20px;
  }
  .paymentInfo {
    width: 90%;
    margin-top: 20px;
  }

  .paymentInfoHeader {
    font-size: 16px;
  }

  .headerDivider {
    margin: 12px 0;
  }

  .subpartContainer {
    margin-bottom: 12px;
  }
}
</style>
