<template>
  <div class="subpartContainer mb-1">
    <div class="paymentSubtitle">Card Information</div>
    <CardInfo @submitPayment="submitPayment" @triggerLoading="triggerLoading" />
  </div>
  <div class="subpartContainer mb-1">
    <div class="paymentSubtitle">Billing Information</div>
    <BillingInfo :showAllBillingInfo="false" />
  </div>
  <!-- <div class="subpartContainer mb-1">
    <div class="paymentSubtitle">Contact Information</div>
    <ContactInfo />
  </div> -->
  <div class="subpartContainer">
    <el-button
      class="submitButton"
      id="tokenizebutton"
      v-loading="loadingPayment"
    >
      Complete Payment
      {{ amountToPay && formatPrice(amountToPay) }}</el-button
    >
  </div>
  <div class="consentMessageContainer">
    <div class="consentMessage">
      By clicking Complete Payment above, you agree for your card provided here
      to be charged the complete amount listed to purchase testing according to
      <a
        href="https://www.vibrant-america.com/website-terms-of-use/"
        target="_blank"
        >Terms of Service</a
      >
      and
      <a
        href="https://www.vibrant-america.com/testing-policies/"
        target="_blank"
        >Testing Policies</a
      >
      from Vibrant America and
      <a href="https://www.vibrant-wellness.com/TermsOfUse" target="_blank"
        >Terms of Service</a
      >
      from Vibrant Wellness.
    </div>
  </div>
</template>

<script>
import BillingInfo from "@/components/payment/BillingInfo.vue";
import CardInfo from "@/components/payment/CardInfo.vue";
// import ContactInfo from "@/components/payment/ContactInfo.vue";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapState, mapActions } from "pinia";
export default {
  name: "CardPayContainer",
  components: {
    BillingInfo,
    CardInfo,
    // ContactInfo,
  },
  data() {
    return {
      paymentMethod: "",
      loadingPayment: false,
      patientId: localStorage.getItem("patient_id"),
      amountToPay: null,
    };
  },
  created() {
    this.setCheckEmptyFields(false);
    this.setAmountToPay();
  },
  computed: {
    ...mapState(usePaymentStore, ["billingInfo"]),
    formatPrice() {
      return (price) => {
        return Number(price).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      };
    },
  },
  methods: {
    ...mapActions(usePaymentStore, [
      "payByCard",
      "setCheckEmptyFields",
      "saveBillingInfo",
    ]),
    setAmountToPay() {
      this.amountToPay = localStorage.getItem("amount_to_pay")
        ? parseFloat(
            (Number(localStorage.getItem("amount_to_pay")) / 100).toFixed(2)
          )
        : this.billingInfo
        ? Number(this.billingInfo.total)
        : null;
    },
    triggerLoading(val) {
      this.loadingPayment = val;
    },
    submitPayment(token) {
      this.$matomo &&
        this.$matomo.trackEvent(
          "pns",
          "payment_page",
          "submitPayByCard",
          1
        );
      this.payByCard(token)
        .then(() => {
          // this.saveBillingInfo();
          this.$router.push("/payment_succeed");
          this.triggerLoading(false);
        })
        .catch((err) => {
          console.log(err);
          this.triggerLoading(false);
          this.$router.push("/payment_failed");
        });
    },
  },
};
</script>

<style scoped>
.consentMessageContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

a {
  color: #20a8c3;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

.consentMessage {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.45px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .consentMessage {
    font-size: 12px;
  }
}
</style>
