<template>
    <div class = "shipping-address-view" v-loading = 'loading'>
        <div v-if = "!loading">
            <ShippingAddressInputsView v-if = "is_editable && !edits_saved" />
            <ChangeAddressStatusView v-if = '!is_editable || edits_saved' />
        </div>
        
    </div>

</template>

<script>
import { useShippingAddressStore } from '@/stores/ShippingAddressStore.ts';
import { useCommonStore } from "@/stores/CommonStore.ts";
import ShippingAddressInputsView from './ShippingAddressInputsView.vue';
import ChangeAddressStatusView from './ChangeAddressStatusView.vue';
// import { env_version } from '@/components/shippingAddress/shippingAddressData/shippingAddressMockData.js';
import { mapState, mapActions } from 'pinia';
export default{
    components:{
        ShippingAddressInputsView,
        ChangeAddressStatusView,
    },
    created(){
        console.log('env_version:', process.env, process.env.VUE_APP_ENV);
        this.setAccessionId(this.$route.query.accession_id);
        this.setSampleId(this.$route.query.sample_id);
        this.storeAllQueryInfomation(this.$route.query);
        this.getShippingAddressInfo();
    },
    computed:{
        ...mapState(useShippingAddressStore,[
            'is_editable',
            'edits_saved',
            'loading',
            'previous_shipping_address_data',
            'valid_concierge_states',
            'direct_to_blooddraw',
            'is_kit_delivered',
        ]),
    },
    watch:{
        // previous_shipping_address_data(newVal){
        //     console.log('previous_shipping_address_data', newVal);
        //     //1. if !edits_saved: updateIsConciergeValid according to previous_shipping_address_data (should only be called once as soon as we get the shipping_address_info via backend api)
        //     if(!this.edits_saved){
        //         if(this.valid_concierge_states.includes(newVal.state)){
        //             this.updateIsConciergeValid(true);
        //         }else{
        //             this.updateIsConciergeValid(false);
        //         }
        //     }
        // },
        is_kit_delivered(newVal){
            //if is_editable is false, the kit is delivered
            if(newVal && this.direct_to_blooddraw){
                this.goToNextStep(this.$router);
            }
        }
    },
    methods:{
        ...mapActions(useShippingAddressStore,[
            'storeAllQueryInfomation',
            'getShippingAddressInfo',
            'updateIsConciergeValid',
            'goToNextStep'
        ]),
        ...mapActions(useCommonStore, [
            'setAccessionId',
            'setSampleId',
        ])
    }
}
</script>

<style scoped>
.shipping-address-view{
    min-height: calc(100vh - 76px);
}
</style>