<template>
  <el-radio border v-show="showApplePayBtn" label="applePay" id="applePayBtn">
    <div class="flex items-center">
      <img
        class="appleImage"
        src="@/assets/payment/apple_pay.svg"
        alt="apple_pay"
      />
    </div>
  </el-radio>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { getPublishableKey } from "@/utils/stripeKeySelector";

export default {
  name: "ApplePayButton",
  data() {
    return {
      publishableKey: getPublishableKey(),
      showApplePayBtn: false,
    };
  },
  mounted() {
    if (this.billingInfo) {
      this.stripeJS();
    }
  },
  computed: {
    ...mapState(usePaymentStore, [
      "billingInfo",
      "sample_id",
      "accession_id",
      "charge_type_id",
    ]),
  },
  methods: {
    ...mapActions(usePaymentStore, ["generateStripeDigitalWalletPayIntent"]),
    stripeJS() {
      // this.showApplePayError = false;
      const stripe = window.Stripe(this.publishableKey);
      const paymentRequest = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label:
            "SampleID: " +
            this.sample_id +
            ",AccessionID: " +
            this.accession_id +
            ",ChargeTypeID: " +
            this.charge_type_id,
          amount: Math.round(this.billingInfo.total * 100),
        },
        disableWallets: ["googlePay", "browserCard", "link"],
      });
      paymentRequest.canMakePayment().then((result) => {
        if (result) {
          if (result.applePay) {
            this.showApplePayBtn = true;
            return;
          }
        } else {
          console.log("error");
        }
      });
      document.getElementById("applePayBtn").addEventListener("click", () => {
        this.$matomo &&
          this.$matomo.trackEvent(
            "pns",
            "payment_page",
            "payment_method:applePay",
            1
          );
        paymentRequest.show();
      });
      paymentRequest.on("paymentmethod", async (e) => {
        try {
          let clientSecret = await this.generateStripeDigitalWalletPayIntent();
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: e.paymentMethod.id,
            },
            {
              handleActions: false,
            }
          );
          if (error) {
            e.complete("fail");
            this.$router.push("/payment_failed");
          }
          if (paymentIntent) {
            e.complete("success");
            this.$router.push("/payment_succeed");
          }
        } catch (error) {
          console.log(error);
          this.$router.push("/payment_failed");
        }
      });
    },
  },
};
</script>

<style scoped>
.el-radio-group {
  display: flex;
  margin: 0px;
  padding: 0px;
  justify-content: space-between;
  flex-wrap: wrap;
}

:deep(.el-radio.is-bordered) {
  width: 27vw;
  /* min-width: 284px; */
  height: 80px;
  border-radius: 4px;
  border: 1px solid #bcccdc;
  margin-bottom: 15px;
  margin-right: 0px;
}

:deep(.el-radio__label) {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.46px;
  color: #000000;
}

:deep(.el-radio.is-bordered.is-checked) {
  border: 1px solid #20a8c3;
}

:deep(.el-radio__input.is-checked + .el-radio__label) {
  font-weight: 500;
  color: #20a8c3;
}

:deep(.el-radio__inner) {
  height: 20px !important;
  width: 20px !important;
  margin-left: 23px;
  margin-right: 23px;
  border: 2px #000000 solid !important;
}

:deep(.el-radio.is-disabled .el-radio__inner) {
  border: 2px #c4c4c4 solid !important;
}

:deep(.el-radio__input.is-checked .el-radio__inner) {
  border: 2px #20a8c3 solid !important;
  background: transparent !important;
}

:deep(.el-radio__input.is-checked .el-radio__inner::after) {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #20a8c3;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px #20a8c3 solid !important;
}

.googleImage,
.appleImage,
.cardImage {
  margin-right: 8px;
}

.radioImage {
  margin-right: 16px;
}

@media only screen and (max-width: 1024px) {
  .el-radio-group {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    flex-wrap: nowrap;
  }

  .el-radio {
    margin-right: 0px;
    width: 100% !important;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .cardImage {
    margin-right: 4px;
    height: 12px;
  }

  .googleImage,
  .appleImage {
    margin-right: 8px;
    height: 20px;
  }

  .radioImage {
    margin-right: 8px;
    height: 20px;
  }

  .el-radio {
    height: 42px !important;
  }

  .el-radio div {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  :deep(.el-radio__inner) {
    height: 16px !important;
    width: 16px !important;
    margin-left: 12px;
    margin-right: 12px;
    border: 1px #000000 solid !important;
  }

  :deep(.el-radio__input.is-checked .el-radio__inner::after) {
    width: 8px;
    height: 8px;
    border: 1px #20a8c3 solid !important;
  }
}
</style>
