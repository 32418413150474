<template>
  <div class="billingContent">
    <PaymentInput
      content="Name on card"
      :isRequired="true"
      width="half"
      @onChange="handleNameOnCardChange"
    />
    <div class="paymentInputContainer">
      <div class="inputTitle">
        Card Number
        <span class="required">*</span>
        <div
          id="card-number"
          :class="showInvalidNumber ? 'redBorder' : ''"
        ></div>
      </div>
      <div v-if="showInvalidNumber" class="warningMessage">
        Invalid Card Number
      </div>
    </div>
  </div>
  <div class="billingContent">
    <PaymentInput
      content="Expiration"
      :isRequired="true"
      width="half"
      @onChange="handeExpirationChange"
    />
    <div class="paymentInputContainer">
      <div class="inputTitle">
        CVC
        <span class="required">*</span>
        <div id="card-cvv" :class="showInvalidCvv ? 'redBorder' : ''" />
      </div>
      <div v-if="showInvalidCvv" class="warningMessage">Invalid Card CVC</div>
    </div>
  </div>
</template>

<script>
import PaymentInput from "@/components/payment/PaymentInput.vue";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapActions, mapState } from "pinia";
export default {
  name: "CardInfo",
  components: {
    PaymentInput,
  },
  mounted() {
    this.stax();
  },
  data() {
    return {
      cardHolder: "",
      expireDate: "",
      validNumber: false,
      validCvv: false,
      webToken:
        process.env.VUE_APP_ENV == "production" &&
        localStorage.getItem("customer_id") != "999997" &&
        localStorage.getItem("customer_id") != "29520" &&
        localStorage.getItem("customer_id") != "29818" &&
        localStorage.getItem("customer_id") != "31157" &&
        localStorage.getItem("customer_id") != "1000002" &&
        localStorage.getItem("customer_id") != ""
          ? process.env.VUE_APP_STAX_PRODUCTION_WEBTOKEN
          : process.env.VUE_APP_STAX_SANDBOX_WEBTOKEN,
      // webToken: process.env.VUE_APP_STAX_SANDBOX_WEBTOKEN,
      showInvalidNumber: false,
      showInvalidCvv: false,
    };
  },
  computed: {
    ...mapState(usePaymentStore, [
      "hasEmptyFields",
      "billing_country",
      "billing_state",
      "billing_zipCode",
      "billing_city",
      "billing_address",
      "billing_address2",
    ]),
  },
  methods: {
    ...mapActions(usePaymentStore, [
      "setCheckEmptyFields",
      "initHasEmptyFields",
    ]),
    handleNameOnCardChange(value) {
      this.cardHolder = value;
    },
    handeExpirationChange(value) {
      this.expireDate = value;
    },
    async stax() {
      const StaxJs = window.StaxJs;
      console.log("staxjs", this.webToken);
      var staxJs = new StaxJs(this.webToken, {
        number: {
          id: "card-number",
          placeholder: "",
          style:
            "height:19px; font-size: 14px; font-weight: 400; letter-spacing: 0.3px;line-height: 19px; width: 100%;",
          format: "prettyFormat",
        },
        cvv: {
          id: "card-cvv",
          placeholder: "",
          style:
            "height:19px; font-size: 14px; font-weight: 400; letter-spacing: 0.3px;line-height: 19px; width: 100%;",
        },
      });
      // tell staxJs to load in the card fields
      staxJs
        .showCardForm()
        .then(() => {
          console.log("form loaded");
        })
        .catch((err) => {
          console.log("error init form " + err);
        });
      staxJs.on("card_form_complete", (message) => {
        console.log("card_form_complete", message);
        this.validNumber = message.validNumber;
        this.validCvv = message.validCvv;
      });

      staxJs.on("card_form_incomplete", (message) => {
        console.log("card_form_incomplete", message);
        this.showInvalidNumber = false;
        this.showInvalidCvv = false;
        this.validNumber = message.validNumber;
        this.validCvv = message.validCvv;
      });

      document.querySelector("#tokenizebutton").onclick = async () => {
        console.log("tokenizebutton clicked");
        this.$emit("triggerLoading", true);
        this.initHasEmptyFields();
        console.log("should return1 stax", this.hasEmptyFields);
        let [expMonth, expYear] = this.expireDate.split("/");
        let [firstName, lastName] = this.cardHolder.split(" ");
        var extraDetails = {
          firstname: firstName,
          lastname: lastName,
          month: expMonth,
          year: 20 + expYear,
          method: "card",
          address_state: this.billing_state,
          country: this.billing_country,
          // zip: this.billing_zipCode,
          // city: this.billing_city,
          // address1: this.billing_address,
          // address2: this.billing_address2,
        };
        this.setCheckEmptyFields(true);
        if (this.validNumber && this.validCvv) {
          this.showInvalidNumber = false;
          this.showInvalidCvv = false;
        } else {
          if (!this.validNumber) {
            this.showInvalidNumber = true;
          }
          if (!this.validCvv) {
            this.showInvalidCvv = true;
          }
        }
        await new Promise((resolve) => setTimeout(resolve, 100));
        console.log("should return2 stax", this.hasEmptyFields);
        if (
          this.hasEmptyFields.length == 0 ||
          this.hasEmptyFields.includes(true) ||
          !this.validNumber ||
          !this.validCvv
        ) {
          console.log("should return3 stax", this.hasEmptyFields);
          window.scrollTo(0, 580);
          this.$emit("triggerLoading", false);
          this.setCheckEmptyFields(false);
          return;
        }
        staxJs
          .tokenize(extraDetails)
          .then((result) => {
            const taxTokenId = result.id;
            this.$emit("submitPayment", taxTokenId);
          })
          .catch((err) => {
            console.log(err);
          });
      };
    },
  },
};
</script>

<style scoped>
.billingContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.paymentInputContainer {
  width: 27vw;
  /* min-width: 284px; */
}
.required {
  color: #ff0000;
}

#card-number {
  box-sizing: border-box;
  height: 42px;
  border: 1px solid #bcccdc;
  border-radius: 4px;
  padding-left: 21px;
  padding-top: 10px;
}

#card-cvv {
  box-sizing: border-box;
  height: 42px;
  border: 1px solid #bcccdc;
  border-radius: 4px;
  padding-left: 21px;
  padding-top: 10px;
}

.warningMessage {
  color: #de583f;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.175px;
  text-align: left;
}

.redBorder {
  border: 1px solid #df2b25 !important;
}

@media only screen and (max-width: 767px) {
  .billingContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .paymentInputContainer {
    width: 100%;
    height: 68px;
  }

  .inputTitle {
    font-size: 12px;
  }

  .warningMessage {
    font-size: 12px;
  }

  #card-number {
    height: 36px;
    padding-left: 15px;
    padding-top: 6px;
  }

  #card-cvv {
    height: 36px;
    padding-left: 15px;
    padding-top: 6px;
  }
}
</style>
