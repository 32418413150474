<template>
    <div class = "shipping-address-inputs-view" v-if = 'is_editable'>
        <div class = "shipping-address-view-title">
            {{ address_page_title_content }}
        </div>
        <div class = 'all-units-wrapper'>
            <div v-for = 'input_unit_info in shipping_address_inputs_structure_data'
            :key = "`${input_unit_info.input_id}-shipping-address-input-unit`"
            :class = "input_unit_info.unit_wrapper_classname">
                <ShippingAddressInputUnit 
                v-model = 'current_shipping_address_data[input_unit_info.input_id]' 
                v-bind = 'input_unit_info' :disabled = "conditionalDisableInput(input_unit_info)"/>
            </div>
        </div>
        <div class = "checkbox-container">
            <el-checkbox v-model="is_apply_to_future_order" 
            :checked = "incomplete_address_in_db"
            :disabled = "incomplete_address_in_db"
            class="apply-to-all-future-order-checkbox">
                <div class = "bold">
                    Apply to All Future Orders
                </div>
                <div class = "regular">
                    By checking "Apply to All Future Orders," the patient address entered here will update the patient's information on file.
                </div>
            </el-checkbox>
        </div>
        <div class = "ops-btns-container">
            <div v-if = "!incomplete_address_in_db && !has_change_address_action && !is_confirmed_or_saved_before" 
            :class = "['save-address-btn',{'full-width':need_blood_draw==null||incomplete_address_in_db}]"
            @click = "directConfirmShippingAddressWithoutChange">
                Confirm Shipping Address
            </div>
            <div v-else-if = "(incomplete_address_in_db) || (!incomplete_address_in_db && (!is_confirmed_or_saved_before || has_change_address_action))"
            :class = "['save-address-btn',{'disable-btn': !allRequiedFieldsFilled},{'full-width':need_blood_draw==null||incomplete_address_in_db}]" 
            @click = 'saveShippingAddress'>
                Save Shipping Address
            </div>

            <div v-if = "need_blood_draw!=null && !incomplete_address_in_db" 
            :class = "[
                'cancel-btn',
                {'full-width':need_blood_draw!=null && !incomplete_address_in_db && is_confirmed_or_saved_before && !has_change_address_action}
            ]"
            @click = "cancelShippingAddress">
                Skip
            </div>
        </div>
        
        <el-dialog
            v-model="confirmAddressDialogVisible"
            class = 'confirm-address-dialog'
        >
            <template #header = '{close}'>
                <div class = 'dialog-header-content'>
                    <img src = "@/assets/BloodDraw/close_icon.svg" @click="close" class = "close-icon"/>
                </div>
            </template>
            <div class  = 'confirm-address-dialog-content'>
                <div class = 'confirm-address-dialog-title'>
                    Please confirm that the address you entered is correct!
                </div>
                <div class = "current-shipping-address-summary">
                    <div class = "summary-row">
                        <div class = 'summary-text'>
                            Name
                        </div>
                        <div class = 'summary-text'>
                            {{this.current_receiver}}
                        </div>
                    </div>
                    <div class = 'summary-divider'></div>
                    <div class = "summary-row">
                        <div class = 'summary-text'>
                            Address
                        </div>
                        <div class = 'summary-text'>
                            {{this.current_address_concatenated}}
                        </div>
                    </div>
                </div>
                <div class = "confirm-ops-btns-div">
                    <div class = "edit-btn" @click="clickEditBtn">Edit</div>
                    <div class = "confirm-btn" @click="clickConfirmBtn">Confirm</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import ShippingAddressInputUnit from '@/components/shippingAddress/ShippingAddressInputUnit.vue';
// import { env_version } from '@/components/shippingAddress/shippingAddressData/shippingAddressMockData.js';
import { mapState, mapActions } from 'pinia';
import { useShippingAddressStore } from "@/stores/ShippingAddressStore.ts";
// import { beta_launch_customer_ids } from "@/utils/betaLaunchData.js";
export default{
    // created(){
    //     console.log('env_version:', env_version);
    //     this.storeAllQueryInfomation(this.$route.query);
    //     this.getShippingAddressInfo();
    // },
    async beforeUnmount(){
        console.log("shipping inputs beforeUnmount", this.edits_saved);
        // record significant address change actions, no matter save or not
        if(this.edits_saved){
            if(this.is_address_change_significant_before_confirmed){
                this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','significant-change-address-action', 1);
            }
        }else{
            const is_address_change_significant = await this.checkWhetherAddressChangeIsSignificant();
            if(is_address_change_significant){
                console.log("shipping inputs beforeUnmount record significant address");
                this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','significant-change-address-action', 1);
            }
        }
        
        // record address change actions, no matter save or not (including only check apply-to-all box)
        if(this.isAddressChanged()){
            if(this.incomplete_address_in_db){
                //matomo track 0-1 input
                this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','0-1-change-address-action', 1);
            }else{
                //matomo track 1-1.1 change
                this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','1-1.1-change-address-action', 1);
            }
        }
        // record checking apply-to-all box (only for 1-1.1)
        if(!this.incomplete_address_in_db && this.is_apply_to_future_order){
            //matomo track 1-1.1 checkbox action
            this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','1-1.1-check-apply-to-all-action', 1);
        }
    },
    components:{
        ShippingAddressInputUnit,
    },
    data(){
        return{
            is_apply_to_future_order:false,
            confirmAddressDialogVisible:false,

            // beta_launch_customer_ids,
        }
    },
    computed:{
        ...mapState(useShippingAddressStore, [
            'shipping_address_inputs_structure_data',
            'previous_shipping_address_data',
            'current_shipping_address_data',
            'required_input_fields',
            'is_editable',
            'need_blood_draw',
            'edits_saved',
            'valid_concierge_states',
            'customer_id',
            'is_express_checkout_beta_launch',
            'is_confirmed_or_saved_before',
            'is_address_change_significant_before_confirmed'
        ]),
        // is_test_beta_launch_customer_id(){
        //     return this.beta_launch_customer_ids.includes(this.customer_id);
        // },
        allRequiedFieldsFilled(){
            for(const requied_field of this.required_input_fields){
                if(!this.current_shipping_address_data[requied_field]){
                    return false;
                }
            }
            return true;
        },
        current_address_concatenated(){
            return `${this.current_shipping_address_data.street}, ${this.current_shipping_address_data.city}, ${this.current_shipping_address_data.state} ${this.current_shipping_address_data.zipcode}, ${this.current_shipping_address_data.country}`;
        },
        current_receiver(){
            return this.current_shipping_address_data.name;
        },
        has_change_address_action(){
            const prev_shipping_address = `${this.previous_shipping_address_data.street}, ${this.previous_shipping_address_data.city}, ${this.previous_shipping_address_data.state} ${this.previous_shipping_address_data.zipcode}, ${this.previous_shipping_address_data.country}`;
            const prev_receiver = this.previous_shipping_address_data.name;
            return prev_shipping_address != this.current_address_concatenated || prev_receiver != this.current_receiver || this.is_apply_to_future_order;
        },
        is_empty_address(){
            return !this.current_shipping_address_data.street || !this.current_shipping_address_data.city || !this.current_shipping_address_data.state || !this.current_shipping_address_data.zipcode || !this.current_shipping_address_data.country;
        },
        incomplete_address_in_db(){
            // console.log('incomplete_address_in_db', !this.previous_shipping_address_data.street || !this.previous_shipping_address_data.city || !this.previous_shipping_address_data.state || !this.previous_shipping_address_data.zipcode || !this.previous_shipping_address_data.country);  
            const output = !this.previous_shipping_address_data.street || !this.previous_shipping_address_data.city || !this.previous_shipping_address_data.state || !this.previous_shipping_address_data.zipcode || !this.previous_shipping_address_data.country;
            if(output){
                console.log('0-1');
                //matomo tracking 0-1 visiting
                this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','0-1-visiting', 1);
            }else{
                console.log('1-1.1');
                //matomo tracking 1-1.1 visiting
                this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','1-1.1-visiting', 1);
            }
            return output;
        },
        address_page_title_content(){
            return this.incomplete_address_in_db ? 'Please Input Your Address' : 'Edit Your Shipping Address If Needed';
        }
    }, 
    watch:{
        current_shipping_address_data:{
            handler(){
                // console.log('current_shipping_address_data', newVal);
            },
            deep:true,
        },
        incomplete_address_in_db(newVal){
            this.is_apply_to_future_order = newVal;
            console.log('incomplete_address_in_db change', newVal);
        },
    },
    methods:{
        ...mapActions(useShippingAddressStore,[
            'storeAllQueryInfomation',
            'getShippingAddressInfo',
            'confirmShippingAddress',
            'updateEditsSaved',
            'goToNextStep',
            'updateIsConciergeValid',
            'checkWhetherAddressChangeIsSignificant',
        ]),
        conditionalDisableInput(input_unit_info){
            //if the structure indicates the disabled field is true, it means that this field is always disabled
            if(input_unit_info.disabled){
                return true;
            }else{
                //if country field has value originally, disable it.
                if(input_unit_info.input_id == "country" && this.previous_shipping_address_data['country']){
                    return true;
                }
            }
            return false;
        },
        cancelShippingAddress(){
            this.goToNextStep(this.$router);
            this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','click-skip-button', 1);
        },
        directConfirmShippingAddressWithoutChange(){
            this.goToNextStep(this.$router);
            this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','direct-confirm-shipping-without-chnange', 1);
        },
        saveShippingAddress(){
            if(this.allRequiedFieldsFilled){
                this.confirmAddressDialogVisible = true;
                this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','click-save-button', 1);
            }
        },
        isAddressChanged(){
            const prev_shipping_address = `${this.previous_shipping_address_data.street}, ${this.previous_shipping_address_data.city}, ${this.previous_shipping_address_data.state} ${this.previous_shipping_address_data.zipcode}, ${this.previous_shipping_address_data.country}`;
            const prev_receiver = this.previous_shipping_address_data.name;
            return prev_shipping_address != this.current_address_concatenated || prev_receiver != this.current_receiver || this.is_apply_to_future_order;
        },
        clickConfirmBtn(){
            this.confirmAddressDialogVisible = false;
            if(this.isAddressChanged()){
                this.confirmShippingAddress(this.is_apply_to_future_order);
            }else{
                this.updateEditsSaved(true);
            }
            this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','click-confirm-button', 1);
        },
        clickEditBtn(){
            this.confirmAddressDialogVisible = false;
            this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','click-edit-button', 1);
        }
    }
    
}
</script>

<style scoped>
.shipping-address-inputs-view{
    padding-left:314px;
    padding-right:318px;
    margin-top:100px;
    min-height: 100%;
}
.shipping-address-view-title{
    padding-bottom:24px;
    width:100%;

    border-bottom: 1px solid #BCCCDC;

    color: #0E4672;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
}
.all-units-wrapper{
    width:100%;
    margin-top:25px;

    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    row-gap:16px;
    column-gap:18px;
}
.checkbox-container{
    margin-top:22px;
}
/* checkbox */
.apply-to-all-future-order-checkbox{
    align-items: baseline;
    height:fit-content;
    --el-checkbox-input-height:18px;
    --el-checkbox-input-width:18px;
    --el-checkbox-input-border:2px solid #000;
    --el-checkbox-checked-icon-color: #FFF;
    --el-checkbox-checked-bg-color:#84BC3B;
    --el-checkbox-checked-input-border-color:#84BC3B;
}
:deep(.apply-to-all-future-order-checkbox .el-checkbox__input.is-checked .el-checkbox__inner::after){
    transform: rotate(45deg) scaleY(1.5) scaleX(1.5) translate(1px);
}
:deep(.apply-to-all-future-order-checkbox .el-checkbox__input){
    transform:translateY(3px);
}
:deep(.apply-to-all-future-order-checkbox .el-checkbox__label){
    padding-left:10px;
    display: flex;
    flex-direction: column;
    gap:4px;
}
.apply-to-all-future-order-checkbox .bold{
    width:100%;
    color: #000;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    white-space:pre-wrap;
    word-break: break-word;
}
.apply-to-all-future-order-checkbox .regular{
    width:100%;
    color: #000;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    white-space:pre-wrap;
    word-break: break-word;
}
/* unit wrapper width */
.Name-unit-wrapper,
.Address-Line-unit-wrapper{
    width:100%;
}

.Zip-Code-unit-wrapper,
.City-unit-wrapper,
.State-unit-wrapper,
.Country-unit-wrapper{
    width:48%;
}
.ops-btns-container{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}
.save-address-btn{
    margin-top:36px;
    padding:8px 0px;
    width:calc(50% - 10px);
    background: #0E4672;
    border-radius: 4px;

    text-align:center;

    color: #FFF;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 500;
    line-height: 26px; /* 173.333% */
    letter-spacing: 0.429px;

    cursor:pointer;
    position: relative;
    z-index: 1;
}
.full-width{
    width:100% !important;
}
.disable-btn{
    color:#BBB;
    background:#F3F3F3;
    cursor: not-allowed;
}
.cancel-btn{
    width: calc(50% - 10px);
    margin-top: 36px;
    text-align: center;
    border-radius: 4px;
    background:#FFF;
    padding:7px 0px;
    border: 1px solid #0E4672;
    color: #0E4672;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 500;
    line-height: 26px; /* 173.333% */
    letter-spacing: 0.429px;

    cursor:pointer;
    position: relative;
    z-index: 1;
}
.dialog-header-content{
    display:flex;
    justify-content:flex-end;
    padding-right:24px;
    padding-top:24px;
    padding-bottom:58px;
}
.close-icon{
    cursor:pointer;
}
.confirm-address-dialog-content{
    padding-left: 96px;
    padding-right:96px;
    padding-bottom:106px;

    display:flex;
    flex-direction:column;
    gap:60px;
}

.confirm-address-dialog-title{
    color: #0E4672;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.458px;
}

.summary-row{
    display:flex;
    justify-content:space-between;
    gap:35px;
}
.summary-text{
    color: #0E4672;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.4px;
}
.summary-divider{
    margin-top:12px;
    margin-bottom:16px;
    display:block;
    content:'';
    height:1px;
    background: #0E4672;

}
.confirm-ops-btns-div{
    display:flex;
    justify-content:center;
    gap:16px;
}
.edit-btn{
    padding: 12px 0px;
    width:27%;
    min-width:132px;
    border-radius: 4px;
    border: 1px solid #0E4672;
    text-align:center;

    color: #0E4672;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    cursor:pointer;
    flex-shrink:1;
}
.confirm-btn{
    padding: 13px 0px;
    width:27%;
    min-width:132px;
    border-radius: 4px;
    background:#0E4672;
    text-align:center;

    color: #FFF;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    cursor:pointer;
    flex-shrink:1;
}
@media only screen and (max-width: 1260px){
    .shipping-address-inputs-view{
        padding-left:157px;
        padding-right:159px;
        margin-top:100px;
    }
    .confirm-address-dialog-content{
        padding-left: 48px;
        padding-right:48px;
        padding-bottom:75px;
        gap:40px;
    }
}

@media only screen and (max-width: 767px){
    .shipping-address-inputs-view{
        padding-left:28px;
        padding-right:27px;
        margin-top:39px;
    }
    .shipping-address-view-title{
        padding-bottom: 12px;
        width: 100%;
        color: #0E4672;
        font-family:'Roboto';
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
    }
    :deep(.apply-to-all-future-order-checkbox .el-checkbox__label){
        gap:2px;
    }
    .apply-to-all-future-order-checkbox .bold{
        width:100%;
        color: #000;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        white-space:pre-wrap;
        word-break: break-word;
    }
    .apply-to-all-future-order-checkbox .regular{
        width:100%;
        color: #000;
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        white-space:pre-wrap;
        word-break: break-word;
    }
    
    .all-units-wrapper{
        width:100%;
        margin-top:12px;

        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        row-gap:8px;
        column-gap:18px;
    }
    .Name-unit-wrapper,
    .Address-Line-unit-wrapper,
    .Zip-Code-unit-wrapper,
    .City-unit-wrapper,
    .State-unit-wrapper,
    .Country-unit-wrapper{
        width:100%;
    }
    .dialog-header-content{
        display:flex;
        justify-content:flex-end;
        padding-right:18px;
        padding-top:18px;
        padding-bottom:18px;
    }
    .confirm-address-dialog-content{
        padding-left: 24px;
        padding-right:24px;
        padding-bottom:46px;
        gap:24px;
    }
    .confirm-address-dialog-title {
        color: #0E4672;
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0.458px;
    }
    .summary-text {
        color: #0E4672;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
    }
    .summary-divider {
        margin-top: 12px;
        margin-bottom: 16px;
    }
    .ops-btns-container{
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        gap:12px;
    }
    .save-address-btn{
        margin-top:24px;
        padding:8px 0px;
        width:100%;
        background: #0E4672;
        border-radius: 4px;

        text-align:center;

        color: #FFF;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        line-height: 26px; /* 173.333% */
        letter-spacing: 0.429px;

        cursor:pointer;
        position: relative;
        z-index: 1;
    }
    .cancel-btn{
        margin-top:0px;
        padding:7px 0px;
        width:100%;
        border: 1px solid #0E4672;
        background:#FFF;
        border-radius: 4px;

        text-align:center;

        color: #0E4672;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        line-height: 26px; /* 173.333% */
        letter-spacing: 0.429px;

        cursor:pointer;
        position: relative;
        z-index: 1;
    }
}


</style>