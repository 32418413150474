import { defineStore } from "pinia";
import {billingService}  from "@/utils/billingRequest";
import {chargeService} from "@/utils/chargeRequest";
import {patientService} from "@/utils/patientRequest";
import {orderBaseURL} from "@/utils/orderRequest";

export const usePaymentStore = defineStore("PaymentStore", {
  state: () => {
    return {
      billingInfo: localStorage.getItem("billingInfo")
        ? JSON.parse(localStorage.getItem("billingInfo"))
        : "",
      loadingPromo: false,
      paymentStatus: "",
      sample_id: localStorage.getItem("sample_id")
        ? localStorage.getItem("sample_id")
        : "",
      accession_id: localStorage.getItem("accession_id")
        ? localStorage.getItem("accession_id")
        : "",
      patient_id: localStorage.getItem("patient_id")
        ? localStorage.getItem("patient_id")
        : "",
      charge_type: localStorage.getItem("charge_type")
        ? localStorage.getItem("charge_type")
        : "",
      charge_type_id: localStorage.getItem("charge_type_id")
        ? localStorage.getItem("charge_type_id")
        : "",
      clinic_name: localStorage.getItem("clinic_name")
        ? localStorage.getItem("clinic_name")
        : "",
      provider_name: localStorage.getItem("provider_name")
        ? localStorage.getItem("provider_name")
        : "",
      customer_id: localStorage.getItem("customer_id")
        ? localStorage.getItem("customer_id")
        : "",
      charge_method: localStorage.getItem("charge_method")
        ? localStorage.getItem("charge_method")
        : "",
      address_needed: localStorage.getItem("address_needed")
        ? localStorage.getItem("address_needed")
        : "",
      blood_draw_needed: localStorage.getItem("blood_draw_needed")
        ? localStorage.getItem("blood_draw_needed")
        : "",
      concierge_needed: localStorage.getItem("concierge_needed")
        ? localStorage.getItem("concierge_needed")
        : "",
      direct_to_blooddraw: localStorage.getItem("direct_to_blooddraw")
        ? localStorage.getItem("direct_to_blooddraw")
        : "",
      count: localStorage.getItem("count") ? localStorage.getItem("count") : "",
      is_markup_order: localStorage.getItem("is_markup_order")
        ? localStorage.getItem("is_markup_order")
        : "",
      billing_email: "",
      billing_phone: "",
      checkEmptyFields: false,
      hasEmptyFields: [],
      billing_country: "",
      billing_state: "",
      billing_address: "",
      billing_address2: "",
      billing_city: "",
      billing_zipCode: "",
      billing_firstName: "",
      billing_lastName: "",

      token:null,
    };
  },
  actions: {
    setChargeType(val) {
      if (val) {
        localStorage.setItem("charge_type", val);
        this.charge_type = val;
      } else {
        localStorage.removeItem("charge_type");
        this.charge_type = "";
      }
    },
    setChargeTypeId(val) {
      if (val) {
        localStorage.setItem("charge_type_id", val);
        this.charge_type_id = val;
      } else {
        localStorage.removeItem("charge_type_id");
        this.charge_type_id = "";
      }
    },
    setCount(val) {
      if (val) {
        localStorage.setItem("count", val);
        this.count = val;
      } else {
        localStorage.removeItem("count");
        this.count = "";
      }
    },
    setIsMarkupOrder(val) {
      if (val !== null && val !== undefined && val !== "") {
        localStorage.setItem("is_markup_order", val);
        this.is_markup_order = val;
      } else {
        localStorage.removeItem("is_markup_order");
        this.is_markup_order = "";
      }
    },
    setJwtToken(val) {
      console.log('setJwtToken PaymentStore', val);
      if (val) {
        this.token = val;
        localStorage.setItem("pnsToken", val);
      } else {
        console.log('remove pnsToken PaymentStore');
        localStorage.removeItem("pnsToken");
      }
    },
    setCheckEmptyFields(val) {
      this.checkEmptyFields = val;
    },
    setConciergeNeeded(val) {
      if (val) {
        localStorage.setItem("concierge_needed", val);
        this.concierge_needed = val;
      } else {
        localStorage.removeItem("concierge_needed");
        this.concierge_needed = "";
      }
    },
    setDirectToBloodDraw(val) {
      if (val) {
        localStorage.setItem("direct_to_blooddraw", val);
        this.direct_to_blooddraw = val;
      } else {
        localStorage.removeItem("direct_to_blooddraw");
        this.direct_to_blooddraw = "";
      }
    },
    setFixedAmountToPay(val) {
      if (val) {
        localStorage.setItem("amount_to_pay", val);
      } else {
        localStorage.removeItem("amount_to_pay");
      }
    },
    initHasEmptyFields() {
      this.hasEmptyFields = [];
    },
    updateHasEmptyFields(val) {
      this.hasEmptyFields.push(val);
    },
    setBillingEmail(val) {
      if (val) {
        this.billing_email = val;
      }
    },
    setBillingPhone(val) {
      this.billing_phone = val;
    },
    setBillingCountry(val) {
      this.billing_country = val;
    },
    setBillingState(val) {
      this.billing_state = val;
    },
    setBillingAddress(val) {
      this.billing_address = val;
    },
    setBillingAddress2(val) {
      this.billing_address2 = val;
    },
    setBillingCity(val) {
      this.billing_city = val;
    },
    setBillingZipCode(val) {
      this.billing_zipCode = val;
    },
    setBillingFirstName(val) {
      this.billing_firstName = val;
    },
    setBillingLastName(val) {
      this.billing_lastName = val;
    },
    async fetchClinicInfo() {
      const params = {
        accessionId: Number(this.accession_id),
      };
      try {
        const res = await patientService.get("/getProviderAndClinicName", {
          params: params,
        });
        this.clinic_name = res.data.clinicName;
        localStorage.setItem("clinic_name", res.data.clinicName);
        this.provider_name = res.data.providerName;
        localStorage.setItem("provider_name", res.data.providerName);
        this.customer_id = res.data.customerId;
        localStorage.setItem("customer_id", res.data.customerId);
        this.charge_method = res.data.chargeMethod;
        localStorage.setItem("charge_method", res.data.chargeMethod);
        this.address_needed = res.data.addressCheckNeeded;
        localStorage.setItem("address_needed", res.data.addressCheckNeeded);
        this.blood_draw_needed = res.data.bloodDrawNeeded;
        localStorage.setItem("blood_draw_needed", res.data.bloodDrawNeeded);
        this.patient_id = res.data.patientId;
        localStorage.setItem("patient_id", res.data.patientId);
        localStorage.setItem(
          "beta_program_enabled",
          res.data.beta_program_enabled
        );
        localStorage.setItem("beta_programs", res.data.beta_programs);
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("failed to fetch clinic info", err);
        localStorage.removeItem("clinic_name");
        localStorage.removeItem("provider_name");
        localStorage.removeItem("customer_id");
        localStorage.removeItem("charge_method");
        localStorage.removeItem("address_needed");
        localStorage.removeItem("blood_draw_needed");
        localStorage.removeItem("patient_id");
        localStorage.removeItem("beta_program_enabled");
        localStorage.removeItem("beta_programs");
        return Promise.reject(err);
      }
    },
    async fetchPatientAddressInfo(){
      try{
        const res = await orderBaseURL.get('/orderTest/patient', {
            headers:{ 
              Authorization: `Bearer ${localStorage.getItem('pnsToken') ? localStorage.getItem('pnsToken') : this.token}`
            },
            params:{
              patientId:this.patient_id
            },
        });
        return res.data.patient_address && res.data.patient_address.length>0
        ? Promise.resolve(res.data.patient_address[0]):Promise.resolve({});
      }catch(err){
        console.log("fetchPatientAddressInfo error:", err);
        return Promise.reject(err);
      }
    },
    async fetchCustomerInfo(){
      const token = localStorage.getItem('pnsToken') ? localStorage.getItem('pnsToken') : this.token;
      const params = {
        jwtToken: token,
      };
      try{
        const res = await orderBaseURL.post('/customer/getCustomerInfo', params,
          {
            headers:{ 
                Authorization: `Bearer ${token}`
              },
          }
        )
        return Promise.resolve(res.data);
      }catch(err){
        console.log("fetchCustomerInfo error:", err);
        return Promise.reject(err);
      }
    },
    async getBillingInfo() {
      const params = {
        charge_type_id: this.charge_type_id,
        charge_type: this.charge_type,
      };
      try {
        const res = await billingService.get("/invoice", { params: params });
        this.billingInfo = res.data;
        console.log("fetch billing info", res.data);
        localStorage.setItem("billingInfo", JSON.stringify(res.data));
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("failed to retrieve billing info", err);
        this.billingInfo = "";
        localStorage.removeItem("billingInfo");
        return Promise.reject(err);
      }
    },
    async applyPromo(promoCode) {
      const params = {
        coupon_code: promoCode,
        need_apply: true,
        charge_url: this.charge_type + "::" + this.charge_type_id,
      };
      this.loadingPromo = true;
      try {
        const res = await billingService.post("/verifyCouponCode", params);
        this.loadingPromo = false;
        this.getBillingInfo();
        return Promise.resolve(res.data);
      } catch (err) {
        this.loadingPromo = false;
        return Promise.reject(err);
      }
    },
    async generateBnplIntent(provider) {
      const params = {
        account_id: this.billingInfo
          ? Number(this.billingInfo.account_id)
          : null,
        account_type: this.billingInfo ? this.billingInfo.account_type : null,
        amount: this.billingInfo ? Number(this.billingInfo.total) : null,
        currency: "usd",
        charge_type: this.charge_type,
        charge_type_id: this.charge_type_id,
        bnpl_provider: provider,
        new_sample: false,
        sample_id: Number(this.sample_id),
        token_platform: this.is_markup_order == true ? "stripe_zb" : "stripe",
      };
      try {
        const res = await chargeService.post("/transaction/payLater", params);
        return Promise.resolve(res.data.client_secret);
      } catch (err) {
        console.log("failed to generate bnpl intent", err);
        return Promise.reject(err);
      }
    },
    async generateStripeCardPayIntent(isHSACard) {
      let amountToPay;
      if (localStorage.getItem("amount_to_pay")) {
        amountToPay = parseFloat(
          (Number(localStorage.getItem("amount_to_pay")) / 100).toFixed(2)
        );
      } else {
        if (this.billingInfo) {
          amountToPay = Number(this.billingInfo.total);
        } else {
          amountToPay = null;
        }
      }
      const params = {
        account_id: this.billingInfo
          ? Number(this.billingInfo.account_id)
          : null,
        account_type: this.billingInfo ? this.billingInfo.account_type : null,
        amount: amountToPay,
        currency: "usd",
        charge_type: this.charge_type,
        charge_type_id: this.charge_type_id,
        new_sample: false,
        sample_id: Number(this.sample_id),
        token_platform: this.is_markup_order == true ? "stripe_zb" : "stripe",
        type: "card",
      };
      if (isHSACard) {
        try {
          const res = await chargeService.post(
            "/transaction/payWithHFSA",
            params
          );
          return Promise.resolve(res.data.client_secret);
        } catch (err) {
          console.log("failed to generate stripe card pay intent", err);
          return Promise.reject(err);
        }
      } else {
        try {
          const res = await chargeService.post(
            "/transaction/pay",
            params
          );
          return Promise.resolve(res.data.client_secret);
        } catch (err) {
          console.log("failed to generate stripe card pay intent", err);
          return Promise.reject(err);
        }
      }
    },
    async generateStripeDigitalWalletPayIntent() {
      const params = {
        account_id: this.billingInfo
          ? Number(this.billingInfo.account_id)
          : null,
        account_type: this.billingInfo ? this.billingInfo.account_type : null,
        amount: this.billingInfo ? Number(this.billingInfo.total) : null,
        currency: "usd",
        charge_type: this.charge_type,
        charge_type_id: this.charge_type_id,
        new_sample: false,
        sample_id: Number(this.sample_id),
        token_platform: this.is_markup_order == true ? "stripe_zb" : "stripe",
      };
      console.log("generate stripe card pay intent", params);
      try {
        const res = await chargeService.post(
          "/transaction/payWithDigitalWallets",
          params
        );
        return Promise.resolve(res.data.client_secret);
      } catch (err) {
        console.log("failed to generate stripe digital pay intent", err);
        return Promise.reject(err);
      }
    },
    async getPaymentStatus() {
      const chargeTypeId = this.charge_type_id;
      const chargeType = this.charge_type;
      try {
        const res = await chargeService.get(
          `/transaction/${chargeTypeId}/type/${chargeType}`
        );
        console.log("payment status", res.data);
        this.paymentStatus = res.data.status;
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("failed to get payment status", err);
        return Promise.reject(err);
      }
    },
    async payByCard(token) {
      let amountToPay;
      if (localStorage.getItem("amount_to_pay")) {
        amountToPay = parseFloat(
          (Number(localStorage.getItem("amount_to_pay")) / 100).toFixed(2)
        );
      } else {
        if (this.billingInfo) {
          amountToPay = Number(this.billingInfo.total);
        } else {
          amountToPay = null;
        }
      }
      const params = {
        account_id: this.billingInfo
          ? Number(this.billingInfo.account_id)
          : null,
        account_type: this.billingInfo ? this.billingInfo.account_type : null,
        amount: amountToPay,
        type: "card",
        token_platform: "stax",
        payment_token: token,
        charge_type: this.charge_type,
        charge_type_id: this.charge_type_id,
        notes: "",
        new_sample: false,
        sample_id: Number(this.sample_id),
      };
      try {
        // console.log('payByCard', localStorage.getItem('pnsToken'), this.token);
        //debug missing token
        if(localStorage.getItem('pnsToken')){
          const res = await chargeService.post("/transaction/pay", params);
          return Promise.resolve(res.data);
        }else{
          const res = await chargeService.post("/transaction/pay", params,
            {
              headers:{ 
                  Authorization: `Bearer ${this.token}`
                },
            }
          );
          return Promise.resolve(res.data);
        }
      } catch (err) {
        console.log("failed to pay by card", err);
        return Promise.reject(err);
      }
    },
    // saveBillingInfo() {
    //   const params = {
    //     email: this.billing_email,
    //     charge_type: this.charge_type,
    //     charge_type_id: this.charge_type_id,
    //     first_name: this.billing_firstName,
    //     last_name: this.billing_lastName,
    //     phone: this.billing_phone,
    //     address_1: this.billing_address,
    //     address_2: this.billing_address2,
    //     address_city: this.billing_city,
    //     address_state: this.billing_state,
    //     address_zip: this.billing_zipCode,
    //     address_country: this.billing_country,
    //   };
    //   console.log("save billing info", params);
    //   chargeService
    //     .post("/billingInfo", params)
    //     .then((res) => {
    //       console.log("save billing info", res.data);
    //     })
    //     .catch((err) => {
    //       console.log("failed to save billing info", err);
    //     });
    // },
  },
});
