import axios from 'axios';
import { ElNotification } from 'element-plus';
const wrapURL = (URL) => {
    const baseURL = axios.create({
        baseURL:URL
    });

    let notificationInstance = null;
    let notFoundNotificationInstance = null;
    let serverErrorNotification = null;

    baseURL.interceptors.response.use(
        (response) => {
            if (response.status !== 200 && response.status !== 201) {
                if (!notificationInstance) {
                    notificationInstance = ElNotification({
                    title: 'Failed',
                    type: 'error',
                    customClass: "errorNotification",
                    message: response.message,
                    duration: 0,
                    onClose: () => {
                        notificationInstance = null;
                    }
                    });
                }
                return Promise.reject("error");
            } else {
                return response;
            }
        },
        (error) => {
            const { status } = error.response;
            if (status === 404) {
                if (!notFoundNotificationInstance) {
                    notFoundNotificationInstance = ElNotification({
                        title: "Failed",
                        message: "Not Found",
                        customClass: "errorNotification",
                        type: "error",
                        duration: 3500,
                        onClose: () => {
                            notFoundNotificationInstance = null;
                        }
                    });
                }
            } else if (status >= 500 && status < 600) {
                console.log(status, "status")
                if (!serverErrorNotification) {
                    serverErrorNotification = ElNotification({
                        title: "Failed",
                        message: "Server Error",
                        customClass: "errorNotification",
                        type: "error",
                        duration: 3500,
                        onClose: () => {
                            serverErrorNotification = null;
                        }
                    });
                }
            } else {
                const errorMessage = error.response.data.message.length
                ? error.response.data.message
                : error.message;
        
                if (!notificationInstance) {
                    notificationInstance = ElNotification({
                        title: "Failed",
                        message: errorMessage,
                        customClass: "errorNotification",
                        type: "error",
                        duration: 3500,
                        onClose: () => {
                            notificationInstance = null;
                        }
                    });
                }
            }
        
            return Promise.reject(error);
            
        }
    );
    return baseURL;
}

export default wrapURL;