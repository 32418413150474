<template>
  <div id = "page-container" >
    <PageHeader :accession_id = "accession_id"/>
    <RouterView />
  <img v-if = "showFooterImage(this.$route.path)" src = '@/assets/footerImage.svg' :class = "['footer-img',{'not-display':!is_editable||edits_saved}]"/>
  </div>
  
</template>

<script>
import PageHeader from "@/components/commons/PageHeader.vue";
import { useCommonStore } from "@/stores/CommonStore.ts";
import { useShippingAddressStore } from '@/stores/ShippingAddressStore.ts';
import { mapState } from "pinia";

export default{
  components:{
    PageHeader,
  },
  computed: {
    ...mapState(useCommonStore, ["sample_id", "accession_id"]),
    ...mapState(useShippingAddressStore,[
      'is_editable',
      'edits_saved',
    ])
  },
  methods:{
    showFooterImage(route_path){
      return route_path == '/uploadShippingAddress';
    }, 
    matchBackgroundColor(route_path){
      // return route_path == "/questionnaire"?'white-bg':'light-blue-bg';
      return route_path == 'white-bg';
    }
  }
}
</script>

<style>
.footer-img{
  position:absolute;
  right:0;
  bottom:0;
  z-index:0;
}
.not-display{
  display: none;
}
@media only screen and (max-width:1260px){
  .footer-img{
    width: 200px;
  }
}
@media only screen and (max-width:767px){
  .footer-img{
    display: none;
  }
}
</style>