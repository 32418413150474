<template>
    <div :class = "['location-unit',{'highlighted':selected},{'in-office-tag':tab_type == 'in_office'}]" @click = "clickLocationCard">
        <div class = "info-wrapper">
            <div class = 'location-name-and-service-type'>
                <div class = "location-name">
                   {{ location_name }}
                </div>
                <div class = 'service-type-tags'>
                    <div v-for = "tag in service_tags" 
                    :key = "`${location_name.replaceAll(' ','_')}-${tag.replaceAll(' ','_')}-tag`"
                    :class = "['tag',{'walk-in':tag == 'Walk In'},{'appointment':tag =='Appointment'}]">
                        {{ tag }}
                    </div>
                </div>
            </div>

            <div class = 'open-hour'>
                <div :class = "['open-hour-text','today-open-hour-wrapper']" @click = "clickTodayOpenHour">
                    <img src = "@/assets/BloodDraw/open_hour_icon.svg"  class = "location-unit-icon" />
                    <div class = 'open-hour-text'>
                        <div :class ="['open-hour-text','day-text']"> {{days_order[0]}} </div> 
                        <div :class ="['open-hour-text']"> {{  open_hour==null?'Closed':convertOpenHourToText(open_hour[days_order[0]]) }} </div>
                    </div>
                    <img src = "@/assets/BloodDraw/open_hour_dropdown_arrow.svg" 
                    :class = "['location-unit-icon','rotate-0', {'rotate-180':show_other_days}]">
                </div>

                <div v-if = "show_other_days" class = "other-days-open-hour-wrapper">
                    <div v-for = 'day in days_order.slice(1)' :class = "['open-hour-text']" :key = "`${location_name.replaceAll(' ','_')}-${day}-open-hour`">
                        <div :class ="['open-hour-text','day-text']"> {{day}} </div> 
                        <div :class ="['open-hour-text']"> {{ open_hour==null?'Closed':convertOpenHourToText(open_hour[day]) }} </div>
                    </div>
                </div>
            </div>

            <div  v-if = "tab_type != 'mobile'" class = 'address'>
                <img src = "@/assets/BloodDraw/address_icon.svg" class = "location-unit-icon" />
                <div class = "address-text">
                   {{ address==''?'No Information':address }}
                </div>
            </div>
        </div>
        <div class = "btns-wrapper">
            <div v-if = "service_tags.includes('Appointment')" class = 'make-appointment-btn' 
            @click = "clickMakeAppointment">
                Make Appointment
            </div>
            <div v-if = "tab_type != 'mobile'" class = 'send-to-my-phone-btn' @click = "clickSendToMyPhone">
                Send to My Phone
            </div>
        </div>
        <el-dialog
            v-model="location_dialog_visible"
            width="56%"
            @click = "clickDialog"
            destroy-on-close
        >
        <template #header="{close}">
            <div class="my-header">
                <img src = "@/assets/BloodDraw/close_icon.svg" @click="close" class = "close-icon"/>
            </div>
        </template>
            <div v-if = "opened_dialog == 'appointment'" class = "location-details">
                <div class = "detail-category">
                    <div class = "category-title">
                        Contact Information
                    </div>
                    <div class = "info-row" @click = "clickWebsiteLink">
                        <img src = "@/assets/BloodDraw/website_icon.svg" />
                        <div :class = "['info-text',{'underlined-link': website != null && website != ''}]">
                            {{ website == null||website =='' ? 'No Infomation' : website }}
                        </div>
                    </div>
                    <div class = "info-row">
                        <img src = "@/assets/BloodDraw/dark_blue_phone_icon.svg" />
                        <div :class = "['info-text',{'underlined-link': phone != null && phone != ''}]">
                            {{ phone == null||phone =='' ? 'No Infomation' : phone }}
                        </div>
                    </div>
                    <div class = "info-row">
                        <img src = "@/assets/BloodDraw/dark_blue_email_icon.svg" />
                        <div :class = "['info-text',{'underlined-link': email != null && email != ''}]">
                            {{ email == null||email =='' ? 'No Infomation' : email }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if = "opened_dialog == 'send to my phone'" class = 'send-to-my-phone-dialog-content'>
                <div class = "input-guidance-text">
                    <span class = "bold">Please enter your phone number here,</span>
                    <span class = "regular">we will send Draw Site name and Address to your phone.</span>
                </div>
                <div class = 'phone-input-wrapper'>
                    <AreaCodePhoneNumberInput ref = 'phone-input' v-model = "phone_number"/>
                </div>
                <div class = 'send-btn' @click = "sendToThisPhone">
                    Send
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import AreaCodePhoneNumberInput from './AreaCodePhoneNumberInput.vue';
import { useBloodDrawStore } from '@/stores/BloodDrawStore.ts';
export default{
    components:{
        AreaCodePhoneNumberInput,
    },
    props:{
        service_tags:{
            type:Array,
            default:()=>[]
        },
        open_hour:{
            type:Object,
            default:()=>{},
        },
        address:{
            type:String,
            default:'',
        },
        location_name:{
            type:String,
            default:'',
        },
        email:{
            type:String,
            default:'',
        },
        phone:{
            type:String,
            default:'',
        },
        website:{
            type:String,
            default:'',
        },
        geo:{
            type:Object,
            default:()=>{},
        },
        tab_type:{
            type:String,
            default:'mobile',
        },
        index:Number,
    },
    created(){
        const today = new Date();
        this.convertDaysToCurOrder(this.days_order, today.getDay());
        // console.log('location_unit', this.location_name, this.index, this.tab_type, this.cur_active_tab);
        //default select first location unit
        if(this.index == 0 && (((this.tab_type == 'in_office' || this.tab_type == 'draw_site')&&(this.cur_active_tab == 'Blood Draw Site')) || (this.tab_type == 'mobile' && this.cur_active_tab == "At-Home Blood Draw"))){
            this.selected = true;
            this.storeSelectedLocationGeoInfo(this.geo);
            this.storeSelectedLocationInfo(this.location_name, this.address);
            // console.log('index 0', this.location_name, this.selected);
        } 
    },
    data(){
        return{
            location_dialog_visible: false,
            days_order:['Sun', 'Mon', 'Tue', 'Wed','Thr','Fri', 'Sat'],
            show_other_days:false,
            opened_dialog:'',
            phone_number:'',
            selected:false,
        }
    },
    computed:{
        ...mapState(useBloodDrawStore,[
            'cur_active_tab',
        ])
    },
    watch:{
        phone_number(newVal){
            console.log('phone-number',newVal);
        },
        location_dialog_visible(newVal){
            if(!newVal){
                this.phone_number = '';
            }
        },
    },
    emits:['select'],
    methods:{
        ...mapActions(useBloodDrawStore,[
            'storeSelectedLocationGeoInfo',
            'storeSelectedLocationInfo',
            'sendInfoToMyPhone',
        ]),
        clickLocationCard(){
            // console.log('click location card', e);
            //only trigger change when this card is not selected
            if(!this.selected){
                //highlight clicked card unit
                this.selected = true;
                //pass the index to parent to update the current selected card index
                this.$emit('select', this.index);
                //store geo info in store for sync map showing
                this.storeSelectedLocationGeoInfo(this.geo);
                this.storeSelectedLocationInfo(this.location_name, this.address);
            }
        },
        clickDialog(e){
            // console.log('click Dialog', e);
            //stop pass event to parent - LocationUnit
            e.stopPropagation();
        },
        clickTodayOpenHour(e){
            //stop pass event to parent - LocationUnit
            e.stopPropagation();
            this.show_other_days = !this.show_other_days;
        },
        clickMakeAppointment(e){
            //stop pass event to parent
            e.stopPropagation();
            //show modal
            this.location_dialog_visible = true;
            this.opened_dialog = "appointment";
        },
        clickWebsiteLink(){
            if(this.website !=null && this.website!=""){
                window.open(this.website, '_blank');
            }
        },
        clickSendToMyPhone(e){
            //stop pass event to parent
            e.stopPropagation();
            //show modal
            this.location_dialog_visible = true;
            this.opened_dialog = "send to my phone";
        },
        // clickDialogCloseBtn(close,e){
        //     e.stopPropagation();
        //     close();
        // },
        async sendToThisPhone(){
            const result = await this.sendInfoToMyPhone(this.phone_number, this.location_name, this.address);
            console.log('call send to my phone api ... the result is', result);
            const target = this.$refs['phone-input'];
            if(result){
                
                if(target){
                    target.$data.show_sent_notice = true;
                }
                setTimeout(()=>{
                    if(target){
                        target.$data.show_sent_notice = false;
                    }
                },3400);
            }else{
                if(target){
                    target.$data.has_alert = true;
                }
            }
        },
        convertDaysToCurOrder(days_order, rotate_times){
            this.reverseArray(days_order, 0, rotate_times-1);
            this.reverseArray(days_order, rotate_times, 6);
            this.reverseArray(days_order, 0, 6);
        },
        reverseArray(arr, start_idx, end_idx){
            while(start_idx < end_idx){
                const temp = arr[start_idx];
                arr[start_idx]= arr[end_idx];
                arr[end_idx]= temp;
                start_idx++;
                end_idx--;
            }
        },
        convertOpenHourToText(open_hours){
            // console.log('convertOpenHourToText', open_hours);
            if(open_hours==null || open_hours.length == 0){
                return 'Closed';
            }else{
                var output_text = '';
                for(const index in open_hours){
                    const cur_range_text = `${open_hours[index].startTime}-${open_hours[index].endTime}`;
                    output_text += cur_range_text;
                    if(index!=open_hours.length-1){
                        output_text +=', ';
                    }
                }
                return output_text;
            }
        }
    }
}
</script>

<style scoped>
.location-unit{
    width:100%;
    height: fit-content;
    padding-left:24px;
    padding-right: 24px;
    padding-top: 22px;
    padding-bottom: 24px;
    box-sizing: border-box;

    border-radius: 8px;
    /* border: 1px solid #BCCCDC; */
    box-shadow: 0px 0px 0px 1px #BCCCDC inset;
    background: #FFF;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:12px;
}
.location-unit.highlighted{
    /* border: 3px solid #20A8C3; */
    box-shadow: 0px 0px 0px 3px #20A8C3 inset;
    padding-top: 22px;
    padding-bottom: 24px;
    padding-left:24px;
    padding-right: 24px;
}
.in-office-tag{
    content:"";
    width: 100%;
    /* height: 100%; */
    position: relative;
}
.in-office-tag::after{
    content:"Your Doctor's Office";
    border-radius: 8px 0px;
    background: #F15B2C;
    padding:4px 23px;

    color: #FFF;
    font-family: 'Roboto';
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.location-name{
    color: #000;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
}

.location-name-and-service-type{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap:12px;
}
.service-type-tags{
    display: flex;
    align-items: center;
    gap:12px;
}
.open-hour{
    display: flex;
    flex-direction: column;
    gap:3px;
    margin-top:10px;
}
.today-open-hour-wrapper{
    cursor: pointer;
    width:fit-content;
}
.other-days-open-hour-wrapper{
    padding-left: 36px;

    display: flex;
    flex-direction: column;
    gap:8px;
}
.address{
    display: flex;
    align-items: center;
    gap:12px;
    margin-top: 8px;
}
.open-hour-text{
    display: flex;
    align-items: center;
    gap: 12px;
    color: #000;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}

.rotate-0 {
  transform: rotate(0deg);
  transition: all 0.5s;
  cursor:pointer;
  margin-left: -4px;
}

.rotate-180 {
  transform: rotate(180deg);
}

.day-text{
    width:24px;
}
.address-text{
    color: #000;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}
.tag{
    padding: 4px 15px;
    width:fit-content;

    font-family: 'Roboto';
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    border-radius: 100px;
}
.walk-in{
    color: #1DA087;
    border: 1px solid #1DA087;
}
.appointment{
    color: #F091CD;
    border: 1px solid #F091CD;
}

.btns-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:8px;
}
.make-appointment-btn{
    padding: 10px 0px;
    width: 166px;
    text-align: center;

    border-radius: 4px;
    background: #0E4672;

    color: #FFF;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;

    cursor:pointer;
}

.send-to-my-phone-btn{
    padding: 9px 0px;
    width: 164px;
    text-align: center;

    border-radius: 4px;
    border: 1px solid #0E4672;
    background: #FFF;

    color: #0E4672;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    
    cursor: pointer;
}
.location-details{
    display:flex;
    flex-direction:column;
    align-items: center;
    gap: 37px;
}
.detail-category{
    display:flex;
    flex-direction:column;
    gap: 16px;
}
.category-title{
    color: #0E4672;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.458px;
}
.info-row{
    display:flex;
    align-items:center;
    gap: 16px;
}
.info-text{
    color: #20A8C3;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    cursor:pointer;
}
.underlined-link{
    text-decoration: underline;
}
/* dialog */
.my-header{
    display:flex;
    justify-content:flex-end;
}
.close-icon{
    cursor:pointer;
}
/* send to my phone dialog */
.send-to-my-phone-dialog-content{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.input-guidance-text{
    color: #0E4672;
    text-align: center;
    font-family: 'Roboto';
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.458px;
}
.input-guidance-text .bold{
    font-weight: 700;
}
.input-guidance-text .regular{
    font-weight: 400;
}
.phone-input-wrapper{
    margin-top:36px;
    width:49%;
    height: 42px;
    text-align: center;
}
.send-btn{
    margin-top:36px;
    width:25%;
    box-sizing: border-box;

    padding:13px 21px;
    border-radius: 4px;
    background: #0E4672;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #FFF;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    cursor:pointer;
}
@media only screen and (max-width: 1260px){
    .input-guidance-text{
        color: #0E4672;
        text-align: center;
        font-family: 'Roboto';
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.458px;
    }
    .phone-input-wrapper{
        margin-top:18px;
        width:80%;
        height: 42px;
        text-align: center;
    }
    .send-btn{
        margin-top: 24px;
        width: 30%;
    }
}
@media only screen and (max-width: 767px){
    .location-unit-icon{
        width:20px;
        height:20px;
    }
    .location-name-and-service-type{
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        flex-direction: column;
        gap:8px;
    }
    .make-appointment-btn{
        padding: 10px 0px;
        width: 136px;
        text-align: center;

        border-radius: 4px;
        background: #0E4672;

        color: #FFF;
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.4px;

        cursor:pointer;
    }

    .send-to-my-phone-btn{
        padding: 9px 0px;
        width: 136px;
        text-align: center;

        border-radius: 4px;
        border: 1px solid #0E4672;
        background: #FFF;

        color: #0E4672;
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
        
        cursor: pointer;
    }
    .detail-category{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .category-title{
        margin-bottom:2px;
        color: #0E4672;
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0.458px;
    }
    .info-row img{
        width: 20px;
        height: 20px;
    }
    .info-text{
        color: #20A8C3;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
    
}
@media only screen and (max-width: 500px){
    .location-unit{
        padding:18px 18px !important;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
    }
    .btns-wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 12px;
    }
    .phone-input-wrapper{
        margin-top:18px;
        width:100%;
        height: 42px;
        text-align: center;
    }
    .send-btn{
        margin-top: 24px;
        width: 56%;
    }
}
</style>