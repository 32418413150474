import { defineStore } from "pinia";
import { shipping_address_inputs_structure_data, getCountryCode } from '@/components/shippingAddress/shippingAddressData/shippingAddressInputsStructure.js';
import { shipping_address_mock_data } from "@/components/shippingAddress/shippingAddressData/shippingAddressMockData";
import { beta_launch_customer_ids } from "@/utils/betaLaunchData.js";
import  { baseURL, inventoryBaseURL}  from "@/components/bloodDraw/BloodDrawData/request";
import { ElNotification } from "element-plus";
// import router from "@/routes";


export const useShippingAddressStore = defineStore('ShippingAddressStore',{
    state:()=>{
        return{
            //the shipping address inputs structure data
            shipping_address_inputs_structure_data,

            previous_shipping_address_data:{},
            current_shipping_address_data:{},
            required_input_fields:[],
            is_editable:true,
            accession_id:null,
            sample_id:null,
            token:null,
            edits_saved:false,
            loading:true,
            is_express_checkout_beta_launch:null,
            is_confirmed_or_saved_before: false,
            is_address_change_significant_before_confirmed:null,

            // for next step
            valid_concierge_states:['CA', 'TX', 'FL'],
            is_concierge_valid:null,
            need_blood_draw:null,
            customer_id:null,
            is_kit_delivered:null,
            direct_to_blooddraw:null,
            
        }
    },
    
    actions:{
        storeAllQueryInfomation(query){
            this.accession_id = query.accession_id;
            this.sample_id = query.sample_id;
            this.token = query.token;
            this.need_blood_draw = query.bloodDrawNeeded?query.bloodDrawNeeded=='true':null;
            this.direct_to_blooddraw = query.direct_to_blooddraw?query.direct_to_blooddraw=='true':null;
            // console.log('direct_to_blooddraw', this.direct_to_blooddraw);
            this.customer_id = query.customer_id;

            // get info from localStorage
            if(localStorage.getItem('beta_program_enabled')!='undefined' && localStorage.getItem('beta_program_enabled')!=null && localStorage.getItem('beta_programs')!=null && localStorage.getItem('beta_programs')!='undefined'){
                const beta_program_enabled = localStorage.getItem('beta_program_enabled') == 'true';
                const beta_programs = localStorage.getItem('beta_programs');

                if(beta_program_enabled && beta_programs.includes('express_checkout')){
                    this.is_express_checkout_beta_launch = true;
                }
            }else{
                this.is_express_checkout_beta_launch =  beta_launch_customer_ids.includes(this.customer_id);
            }
        },
        goToNextStep(router){
            //if need_blood_draw: go to blodDraw Page
            const url_params = {
                customer_id:this.customer_id,
                sample_id:this.sample_id,
                accession_id:this.accession_id,
                token:this.token,
                is_concierge_valid:this.is_concierge_valid?true:false,
                // is_kit_delivered: this.is_kit_delivered,
            };
            // console.log(' url_params',  url_params);
            if(this.is_kit_delivered!=null){
                url_params['is_kit_delivered'] = this.is_kit_delivered;
            }
            if(this.need_blood_draw){
                router.push({
                    name: 'bloodDrawView',
                    query: url_params
                });
            }else{
                //else: VW page
                window.location.href = 'https://www.vibrant-wellness.com/collection/';
            }
        },
        getShippingAddressInfo(){
            console.log('call /ifComfirmAddressV2 api ...');
            baseURL.get('/utility/ifConfirmAddressV2', {
                headers:{ 
                    Authorization: `Bearer ${this.token}`
                },
                params:{
                    sample_id: this.sample_id,
                    accession_id: this.accession_id,
                },
            }).then((res)=>{
                // first assign shipping address to help check concierge validation
                this.previous_shipping_address_data = res.data.result;
                this.current_shipping_address_data = JSON.parse(JSON.stringify(res.data.result));
                // 1. check country field of res.data.result to assign country input's dropdown options
                for(const input_unit_info of this.shipping_address_inputs_structure_data){
                    if(input_unit_info.input_id == "country"){
                        //  1.1 if country is empty:
                        //     -> only show 'US' as the option to limit the delivery range to avoid not paying delivery fee for international delivery
                        //     -> set the autofilled input's customized_props's search_range to be "US" (customized_props has all props except for value that the customized unit component has)
                        if(!this.previous_shipping_address_data.country){
                            input_unit_info.dropdown_options = [{
                                label: 'United States(US)',
                                value: 'US'
                            }];
                            //find autofilled input info and set its search_range
                            for(const input_unit_info of this.shipping_address_inputs_structure_data){
                                if(input_unit_info.input_id == 'street'){
                                    input_unit_info.customized_props={
                                        search_range:'US',
                                    };
                                }
                            }  
                        }else{
                        //  1.2 if country has value:
                        //     -> do nothing -> all options (which is assigned as the default version) for dropdown 
                        //     -> add disabled:true for input field
                        //     -> set the autofilled input's customized_props's search_range to be the corresponding country code (all lower cases, cleaning and match non-country code data via countries.js)
                            input_unit_info.disabled = true;
                            const current_country_code = getCountryCode(this.previous_shipping_address_data.country);
                            //find autofilled input info and set its search_range
                            for(const input_unit_info of this.shipping_address_inputs_structure_data){
                                if(input_unit_info.input_id == 'street'){
                                    input_unit_info.customized_props = {
                                        search_range:current_country_code
                                    };
                                }
                            }  
                        }
                    }
                }
                    
                //2. check editable
                this.is_editable = res.data.result.is_editable;
                //3. check initial concierge validation according to inital address
                this.is_concierge_valid = res.data.result.is_sample_address_valid_concierge;
                //4. check kit status for different status view page
                this.is_kit_delivered = res.data.result.reason == 'DELIVERED';
                //5. end the loading
                this.loading = false;
            }).catch((err)=>{
                console.log("/ifConfirmAddress api error:", err);
                this.is_editable = false;
                this.loading = false;
            })
            // this.previous_shipping_address_data = shipping_address_mock_data;
            // this.current_shipping_address_data = JSON.parse(JSON.stringify(shipping_address_mock_data));

            // check whether current sample's shippingn address is confirmed or saved before
            inventoryBaseURL.post('/orders/samples/kits/shipping-address/check',
            {
                sample_id_list: [this.sample_id],
            },
            {
                headers:{ 
                    Authorization: `Bearer ${this.token}`
                },
            }).then((res)=>{
                console.log('res', res.data.data.address_confirmed_samples, this.sample_id);
                if(res.data.data.address_confirmed_samples.includes(this.sample_id)){
                    this.is_confirmed_or_saved_before = true;
                }else{
                    this.is_confirmed_or_saved_before = false;
                }
            }).catch((err)=>{
                this.is_confirmed_or_saved_before = false;
                ElNotification({
                    title: 'Failed',
                    type: 'error',
                    customClass: "errorNotification",
                    message: 'Check Whether Confirmed Before Failed',
                    duration: 0,
                });
            });
        },
        async confirmShippingAddress(is_apply_to_all_future_order){
            console.log('call /confirmAddress api...');
            //need to check whether changes are significant before actually change the shipping address
            const is_address_change_significant = await this.checkWhetherAddressChangeIsSignificant();
            this.is_address_change_significant_before_confirmed = is_address_change_significant;

            baseURL.post('/utility/confirmAddress',
            {
                sample_id: this.sample_id,
                street:this.current_shipping_address_data.street,
                city:this.current_shipping_address_data.city,
                state:this.current_shipping_address_data.state,
                zipcode:this.current_shipping_address_data.zipcode,
                country:this.current_shipping_address_data.country,
                name:this.current_shipping_address_data.name,
                is_address_applied_to_future: is_apply_to_all_future_order,
            },
            {
                headers:{ 
                    Authorization: `Bearer ${this.token}`
                },
            }).then((res)=>{
                console.log('save shipping address', res.data.status_code);
                if(res.data.status_code == 200){
                    this.edits_saved = true;
                    this.is_concierge_valid = res.data.result.data.is_sample_address_valid_concierge;
                }else{
                    ElNotification({
                        title: 'Failed',
                        type: 'error',
                        customClass: "errorNotification",
                        message: 'Shipping Address Save Failed',
                        duration: 0,
                    });
                }
            }).catch((err)=>{
                console.log('/confirmAddress api error:',err);
            })
        },
        async checkWhetherAddressChangeIsSignificant(){
            // console.log('checkWhetherAddressChangeIsSignificant');
            try{
                const response = await inventoryBaseURL.post('/orders/samples/shipping-address/changes/check',
                {
                    sample_id: this.sample_id,
                    street:this.current_shipping_address_data.street,
                    city:this.current_shipping_address_data.city,
                    state:this.current_shipping_address_data.state,
                    zipcode:this.current_shipping_address_data.zipcode,
                    country:this.current_shipping_address_data.country,
                    name:this.current_shipping_address_data.name,   
                },
                {
                    headers:{ 
                        Authorization: `Bearer ${this.token}`
                    },
                });
                return response.data.data.address_verified_by_patient_samples;
            }catch(err){
                console.log('checkWhetherAddressChangeIsFake error', err);
                return true;
            }
        },
        updateEditsSaved(val){
            this.edits_saved = val;
        },
        updateCurrentShippingAddress(key, val){
            //if update key is country: only allow update when originally country value is empty
            if(key == 'country'){
                if(!this.previous_shipping_address_data['country']){
                    this.current_shipping_address_data[key] = val;
                }
            }else{
                this.current_shipping_address_data[key] = val;
            }
        },
        addRequiredInputFields(val){
            this.required_input_fields.push(val);
        },
        updateIsConciergeValid(val){
            this.is_concierge_valid = val;
        }
    }
})