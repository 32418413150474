import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createPinia } from 'pinia'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './routes'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import VueMatomo from 'vue-matomo';

import './styles/main.css';
import './index.css' //tailwind css
import './styles/payment.css'
import './styles/questionnaire.css'
import './styles/nyform.css'

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

const app = createApp(App);

app.use(ElementPlus);
app.use(createPinia());
app.use(VueAxios, axios);
app.use(router);
app.use(VueTelInput);
app.use(VueMatomo, {
  host: "https://www.vibrant-america.com/matomo/",
  siteId: 5,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: [] 
})

process.env.VUE_APP_ENV == "production" && Sentry.init({
    app,
    dsn: "https://4cc25790d21d4ad6b3ec268987b0337e@sentry1.vibrant-america.com/41",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "https://pns.vibrant-wellness.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
  });
Sentry.setTag("service-name", "PNS");

if (window.location.href.includes('#/')) {
    console.log('hash found');
    const newUrl = window.location.href.replace('#/', '');
    console.log('newUrl', newUrl);
    window.location.href = newUrl;
}

app.mount('#app');

