<template>
  <div class="subpartContainer payLaterContainer">
    <div class="paymentSubtitle">Pay Later Option</div>
    <PaymentRadio :options="PayLaterOptions" @onChange="handlePayLaterChange" />
  </div>
  <div class="subpartContainer mb-1" v-if="payLaterOption != ''">
    <div class="paymentSubtitle">Billing Information</div>
    <BillingInfo :showAllBillingInfo="true" />
  </div>
  <div class="subpartContainer mb-1" v-if="payLaterOption != ''">
    <div class="paymentSubtitle">Contact Information</div>
    <ContactInfo />
  </div>
  <div class="subpartContainer" v-if="payLaterOption != ''">
    <el-button
      class="submitButton"
      @click="submitPayment"
      v-loading="loadingPayment"
    >
      Proceed to Pay
    </el-button>
  </div>
</template>

<script>
import PaymentRadio from "@/components/payment/PaymentRadio.vue";
import PayLaterOptions from "@/utils/payLaterOptions.js";
import BillingInfo from "@/components/payment/BillingInfo.vue";
import ContactInfo from "@/components/payment/ContactInfo.vue";
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapState, mapActions } from "pinia";
import { getPublishableKey } from "@/utils/stripeKeySelector";
export default {
  name: "BnplContainer",
  components: {
    PaymentRadio,
    BillingInfo,
    ContactInfo,
  },
  data() {
    return {
      publishableKey: getPublishableKey(),
      payLaterOption: "",
      PayLaterOptions,
      returnURL: "https://pns.vibrant-wellness.com/payment_status?bnpl=true",
      loadingPayment: false,
      patientId: localStorage.getItem("patient_id"),
    };
  },
  computed: {
    ...mapState(usePaymentStore, [
      "hasEmptyFields",
      "billing_country",
      "billing_state",
      "billing_zipCode",
      "billing_city",
      "billing_address",
      "billing_address2",
      "billing_email",
      "billing_firstName",
      "billing_lastName",
    ]),
  },
  created() {
    this.setCheckEmptyFields(false);
  },
  methods: {
    ...mapActions(usePaymentStore, [
      "generateBnplIntent",
      "setCheckEmptyFields",
      "initHasEmptyFields",
      "saveBillingInfo",
    ]),
    handlePayLaterChange(value) {
      this.payLaterOption = value;
    },
    async submitPayment() {
      this.$matomo && this.$matomo.trackEvent("pns", "payment_page", "submitBnpl", 1);
      let clientSecret;
      this.setCheckEmptyFields(true);
      this.initHasEmptyFields();
      this.loadingPayment = true;
      await new Promise((resolve) => setTimeout(resolve, 100));
      if (
        this.hasEmptyFields.length == 0 ||
        this.hasEmptyFields.includes(true)
      ) {
        console.log("should return", this.hasEmptyFields);
        this.loadingPayment = false;
        return;
      }
      if (this.payLaterOption == "klarna") {
        try {
          clientSecret = await this.generateBnplIntent("klarna");
        } catch (error) {
          console.log(error);
          this.loadingPayment = false;
          this.$router.push("/payment_failed");
        }
      } else if (this.payLaterOption == "afterpay") {
        try {
          clientSecret = await this.generateBnplIntent("afterpay_clearpay");
        } catch (error) {
          console.log(error);
          this.loadingPayment = false;
          this.$router.push("/payment_failed");
        }
      }
      const stripe = window.Stripe(this.publishableKey);
      const paymentDetails = {
        payment_method: {
          billing_details: {
            email: this.billing_email,
            name: this.billing_firstName + " " + this.billing_lastName,
            address: {
              country: this.billing_country,
              line1: this.billing_address,
              line2: this.billing_address2,
              city: this.billing_city,
              state: this.billing_state,
              postal_code: this.billing_zipCode,
            },
          },
        },
        shipping: {
          name: this.billing_firstName + " " + this.billing_lastName,
          address: {
            country: this.billing_country,
            line1: this.billing_address,
            line2: this.billing_address2,
            city: this.billing_city,
            state: this.billing_state,
            postal_code: this.billing_zipCode,
          },
        },
        return_url: this.returnURL,
      };
      let paymentIntent, error;
      if (this.payLaterOption == "klarna") {
        ({ paymentIntent, error } = await this.handleConfirmKlarnaPayment(
          stripe,
          clientSecret,
          paymentDetails
        ));
      } else if (this.payLaterOption == "afterpay") {
        ({ paymentIntent, error } = await this.handleConfirmAfterpayPayment(
          stripe,
          clientSecret,
          paymentDetails
        ));
      }
      if (paymentIntent) {
        this.loadingPayment = false;
        this.handlePopupRedirect(paymentIntent);
        // this.saveBillingInfo();
      }
      if (error) {
        this.loadingPayment = false;
        this.$router.push("/payment_failed");
      }
    },
    async handleConfirmKlarnaPayment(stripe, clientSecret, paymentDetails) {
      try {
        const { paymentIntent, error } = await stripe.confirmKlarnaPayment(
          clientSecret,
          paymentDetails,
          { handleActions: false }
        );
        return { paymentIntent, error };
      } catch (error) {
        console.error("Error confirming Klarna payment:", error);
        return { error };
      }
    },
    async handleConfirmAfterpayPayment(stripe, clientSecret, paymentDetails) {
      try {
        const { paymentIntent, error } =
          await stripe.confirmAfterpayClearpayPayment(
            clientSecret,
            paymentDetails,
            { handleActions: false }
          );
        return { paymentIntent, error };
      } catch (error) {
        console.error("Error confirming Afterpay payment:", error);
        return { error };
      }
    },
    handlePopupRedirect(paymentIntent) {
      // const isMobileDevice =
      //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      //     navigator.userAgent
      //   );
      // if (isMobileDevice) {
      window.location.href = paymentIntent.next_action.redirect_to_url.url;
      // } else {
      //   const modal = document.createElement("div");
      //   modal.style.position = "fixed";
      //   modal.style.top = "0";
      //   modal.style.left = "0";
      //   modal.style.width = "100%";
      //   modal.style.height = "100%";
      //   modal.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
      //   modal.style.zIndex = 9999;
      //   modal.style.display = "none";
      //   document.body.appendChild(modal);
      //   const width = 500;
      //   const height = 1000;
      //   const left = (window.innerWidth - width) / 2;
      //   const top = (window.innerHeight - height) / 2;
      //   const popup = window.open(
      //     "",
      //     "_blank",
      //     `width=${width},height=${height},left=${left},top=${top}`
      //   );
      //   modal.appendChild(popup.document.documentElement);
      //   modal.style.display = "block";
      //   popup.location.href = paymentIntent.next_action.redirect_to_url.url;
      //   let windowCheck = setInterval(() => {
      //     if (popup.closed) {
      //       clearInterval(windowCheck);
      //       modal.style.display = "none";
      //       document.body.removeChild(modal);
      //       this.$router.push({
      //         path: "/payment_status",
      //         query: {
      //           bnpl: true,
      //         },
      //       });
      //     }
      //   }, 1000);
    },
  },
};
</script>

<style scoped>
.payLaterContainer {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .payLaterContainer {
    margin-bottom: 12px;
  }
}
</style>
